.tasks-header {
    display: grid;
    grid-template-columns: 78px 234px auto;
    align-items: center;
    grid-column-gap: 20px;
    margin: 20px 0;
}

.tasks-header img {
    width: 100%;
}

.tasks .mdhui-card-title {
    color:#000;
    font-weight: bold;
    font-size: 20px;
}


.tasks .mdhui-single-survey-task.complete {
    width: auto;
}

