.dashboard-toc {
    margin-top: 20px;
}

.dashboard-toc-item {
    background: #CAC8AA;
    cursor: pointer;
    border-radius: 7px;
    aspect-ratio: 1 / 1;
    padding: 8px;
    text-align: center;
}

.dashboard-toc-item-icon-multiple {
    position: relative;
    height: 80%;
}

.dashboard-toc-item-icon-multiple .icon-small:first-child {
    position: absolute;
    background: #E1DEC0;
    top: 35%;
    left: 35%;
}

.dashboard-toc-item-icon-multiple .icon-small:last-child {
    position: absolute;
    top: 5%;
    left: 15%;
}

.dashboard-toc-item-name {
    padding: 8px 0 0;
}


