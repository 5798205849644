.chart-period-selector {
    background: #EEE;
    display: grid;
    grid-template-columns: 1fr 1px 1fr 1px 1fr;
    align-items: center;
    justify-items: center;
    border-radius: 6px;
    margin: 12px 12px 0 12px;
    padding: 4px;
}

.chart-period {
    width: 100%;
    text-align: center;
    border-radius: 6px;
}

.chart-period-selected {
    background: #FFF;
    padding: 4px 0;
}

.chart-period-divider {
    background: #D9D9D9;
    width: 100%;
}

.chart .recharts-label {
    text-anchor: middle;
    font-size: 12px;
    font-weight: bold;
    fill: #575294;
}
